
function getMenuList(lang) {
    if(lang.langFlag === 'false') {
        return "<ul id='hamburger_options' class='hamburger'><li id='home'>Restart</li><li id='feedback'>Feedback</li><li id='tutorials'>Tutorials</li></ul>";
    } else {
        switch(lang.userSaveLang) {
            case 'es' :
                return "<ul id='hamburger_options' class='hamburger'><li id='home'>Reanudar</li><li id='feedback'>Realimentación</li><li id='tutorials'>Tutoriales</li><li id='preferred_language'>Idioma Preferido</li></ul>";
            case 'pt' :
                return "<ul id='hamburger_options' class='hamburger'><li id='home'>Reanudar</li><li id='feedback'>Retorno</li><li id='tutorials'>Tutoriales</li><li id='preferred_language'>Idioma Preferido</li></ul>";
            case 'nl' :
                return "<ul id='hamburger_options' class='hamburger'><li id='home'>Herstarten</li><li id='feedback'>Terugkoppeling</li><li id='tutorials'>zelfstudie</li><li id='preferred_language'>Voorkeurstaal</li></ul>";
            default: 
                return "<ul id='hamburger_options' class='hamburger'><li id='home'>Restart</li><li id='feedback'>Feedback</li><li id='tutorials'>Tutorials</li><li id='preferred_language'>Preferred Language</li></ul>";
        }
    }
}

function getWhatsNewFeature(lang) {
    if(lang.langFlag === 'false') {
        return `<div id="notification"><span class="notification_close_btn">X</span><p class="notification_hd">Check out what's new</p><div id="newFeaturePoints"></div><button type="button">What's New</button></div>`;
    } else {
        switch(lang.userSaveLang) {
            case 'es' :
                return `<div id="notification"><span class="notification_close_btn">X</span><p class="notification_hd">Mira las novedades</p><div id="newFeaturePoints"></div><button type="button">Qué hay de nuevo</button></div>`;
            case 'nl' :
                return `<div id="notification"><span class="notification_close_btn">X</span><p class="notification_hd">Bekijk wat er nieuw is</p><div id="newFeaturePoints"></div><button type="button">Wat is er nieuw</button></div>`;
            case 'pt' :
                return `<div id="notification"><span class="notification_close_btn">X</span><p class="notification_hd">Confira o que há de novo</p><div id="newFeaturePoints"></div><button type="button">Wat is er nieuw</button></div>`;
            default: 
                return `<div id="notification"><span class="notification_close_btn">X</span><p class="notification_hd">Check out what's new</p><div id="newFeaturePoints"></div><button type="button">What's New</button></div>`;
        }
    }
}

function getCDSMenuList() {
    return "<ul id='hamburger_options' class='hamburger'><li id='home'>Restart</li><li id='viewMyList'>View My List</li><li id='feedback'>Feedback</li><li id='tutorials'>Tutorials</li></ul>"; 
}

const hambugerMenu = {
    getMenuList: getMenuList,
    getWhatsNewFeature: getWhatsNewFeature,
    getCDSMenuList: getCDSMenuList
};

export default hambugerMenu;